<template>
<div>
    <span>Register</span>
</div>
</template>

<script>
export default {
    name: 'Register'
}
</script>

<style scoped>

</style>
